.Claymate {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 248px;
  margin: 4px;
  height: calc(var(--bottomSpacing) - 4 * 4px);
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr auto auto;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: saturate(100%) blur(10px);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px;
  transition: box-shadow 0.5s ease-in-out;
  z-index: 1;
}

@media (max-width: 640px) {
  .Claymate {
    left: 0;
  }
}

@media (max-width: 1000px) and (max-height: 500px) {
  .Claymate {
    left: 0;
  }
}

.Claymate-buttons {
  display: grid;
  grid-gap: 2px;
  grid-template-rows: repeat(auto-fit, 30px);
}

.Claymate-buttons button {
  margin: 0.125rem;
  padding: 0.25rem;
}

.Claymate button {
  user-select: none;
  background-color: #e9ecef;
  border: 0;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
}

.Claymate button:focus {
  outline: transparent;
  box-shadow: 0 0 0 2px #a5d8ff;
}

.Claymate button:hover {
  background-color: #ced4da;
}

.Claymate button:active {
  background-color: #adb5bd;
}

.Claymate button:disabled {
  cursor: not-allowed;
}

.Claymate-scenes {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 10px;
}

.Claymate-scene {
  height: 100%;
  border-left: lightgray solid 1px;
  position: relative;
  width: 128px;
  min-width: 128px;
}

.Claymate-current-scene {
  border: 1px dotted gray;
}

.Claymate-scene canvas {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.Claymate-scene:hover .Claymate-delete,
.Claymate-scene:hover .Claymate-left,
.Claymate-scene:hover .Claymate-right {
  display: block;
}

.Claymate-delete {
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  padding: 0;
}

.Claymate-left {
  display: none;
  position: absolute;
  bottom: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
  padding: 0;
}

.Claymate-right {
  display: none;
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  padding: 0;
}
